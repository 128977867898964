<template>
    <div class="pcdiy-box" v-loading="loading">
        <div v-for="(item) in viewContent" :key="item.id">
            <div v-if="item.block_id == 1" v-html="item.content"></div>
            <div v-if="item.block_id == 2 && item.goods && item.goods.length > 0" class="list-wrap" :style="item.style">
                <div class="goods-info">
                    <div class="item" v-for="(citem, cindex) in item.goods" :key="cindex"
                        @click="$router.pushToTab({ path: '/sku-' + citem.id + '?source_type=0' })">
                        <div class="goods-wrap">
                            <div class="img-wrap"><img alt="商品图片" :src="$img(citem.image, { size: 'mid' })"
                                    @error="imageError(cindex)" /></div>
                            <h3>
                                <span class="p_tag_832" v-if="citem.tag_type == 1">832产品</span>
                                <span class="p_tag_832" v-if="citem.tag_level == 2">省贫认定</span>
                                <span class="p_tag_832" v-if="citem.tag_level == 3">市贫认定</span>
                                <span class="p_tag_832" v-if="citem.tag_level == 4">县贫认定</span>
                                {{ citem.title }}
                            </h3>
                            <p class="price">
                                <label>￥</label>{{ citem.price }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getViewByPcdiy } from "@/api/goods/goods"
export default {
    name: "list",
    components: {

    },
    computed: {

    },
    data: () => {
        return {
            loading: true,
            viewContent: [],
        }
    },
    mounted() {
        let pcdiy_viewid = parseInt(this.$route.query.view_id)
        this.getViewContent(pcdiy_viewid)
    },
    methods: {
        getViewContent(pcdiy_viewid) {
            getViewByPcdiy({ id: pcdiy_viewid })
                .then(res => {
                    if (res.code == 0)
                        this.viewContent = res.data.view_info['view_floor']
                    // console.log('==',res)
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
    }
};
</script>
<style lang="scss" scoped>
.pcdiy-box {
    width: 1210px;
    min-height: 400px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    background-color: #fff;
}

.list-wrap {
    width: 1210px;
    overflow: hidden;
    min-height: 500px;
    padding: 35px 0px;
    margin: 0 auto;

    .goods-info {
        width: 1160px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 20%;
            margin: 0;
            cursor: pointer;
            padding: 0;
            transition: all 0.2s linear;

            .goods-wrap {
                margin: 0 10px 20px;
                padding: 15px 0px;
                box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                min-height: 250px;
                background: #fff;

                &:hover {
                    z-index: 2;
                    -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
                    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
                    -webkit-transform: translate3d(0, -2px, 0);
                    transform: translate3d(0, -2px, 0);
                }

                .img-wrap {
                    width: 180px;
                    height: 180px;
                    margin: 0px auto;
                    line-height: 180px;
                    /*box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);*/
                    /*border-radius: 16px;*/
                    overflow: hidden;
                    position: relative;

                    img {
                        border: none;
                        position: absolute;
                        margin-right: -75px;
                        top: 50%;
                        left: 50%;
                        display: block;
                        transform: translate(-50%, -50%);
                    }
                }

                h3 {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    height: 44px;
                    text-align: left;
                    -webkit-box-orient: vertical;
                    margin: 8px 15px 3px;

                    .p_tag_832 {
                        background-color: #e1251b;
                        border-radius: 2px;
                        color: #fff;
                        padding: 0 5px;
                        margin-right: 4px;
                        line-height: 16px;
                        font-size: 12px;
                        font-family: Helvetica Neue, Hiragino Sans GB, SimSun, serif;
                    }
                }

                .price {
                    margin: 0 10px;
                    font-size: 18px;
                    line-height: 20px;
                    text-align: left;
                    color: $base-color;
                    font-weight: bold;

                    label {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>
